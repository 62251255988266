import React, { useReducer } from "react";
import { ServiceContext } from "../Contexts.js";
import serviceReducer from './ServicesReducer.js';

const ServiceState = props => {
    const initialState = {
        services: [
            {
                title: 'TMJ Massage',
                description: 'A focused treatment targeting the neck, jaw & face to relieve pain and tension from Temporomandibular Joint (TMJ) dysfunction. Ideal for reducing jaw pain, headaches & tension caused by clenching, grinding &/or stress.',
                price30: null,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Swedish',
                description: 'Relaxing, gentle massage that promotes overall wellness and reduces stress.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Deep Tissue',
                description: 'Focused on relieving tension in deeper muscle layers for chronic pain relief.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Prenatal',
                description: 'Tailored for expectant mothers to ease discomfort and promote relaxation.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Lymphatic Wellness',
                description: 'Gentle massage to encourage lymphatic drainage and boost the immune system.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Sports',
                description: 'Targeted therapy for athletes, improving flexibility, performance, and recovery.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Stretching',
                description: 'Assisted stretching to enhance flexibility, mobility, and muscle recovery.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Reiki',
                description: 'Energy healing technique to balance the body\'s energy and promote emotional well-being.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Reiki/Massage Combo',
                description: 'Combines the healing touch of massage with the balancing effects of Reiki.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            },
            {
                title: 'Couples Massage',
                description: 'Allows partners to enjoy side-by-side massages in a peaceful environment.',
                price30: 90,
                price60: 180,
                price90: 270,
                price120: 360,
            },
            {
                title: 'Therapeutic Massage',
                description: 'A targeted massage to relieve tension, reduce pain, and restore balance, tailored to your unique needs for enhanced well-being.',
                price30: 45,
                price60: 90,
                price90: 135,
                price120: 180,
            }
        ],
        addons: [
            {
                title: 'CBD',
                description: 'Infuses massage with CBD oil to reduce inflammation and promote deep relaxation.',
                price: 20,
            },
            {
                title: 'Cooling Muscle Rub',
                description: 'Cooling therapy to relieve muscle pain and soreness.',
                price: 15,
            },
            {
                title: 'Cupping',
                description: 'Traditional therapy using suction cups to improve circulation and relieve muscle tension.',
                price: 20,
            },
            {
                title: 'Hot Stones',
                description: 'Warm stones are used to deeply relax muscles and enhance the massage experience.',
                price: 15,
            },
            {
                title: 'Magnesium',
                description: 'Applied during massage to soothe muscle aches/spasms and improve skin health.',
                price: 15,
            },
            {
                title: 'Red Light Therapy',
                description: 'Stimulates collagen production, reduces inflammation, and promotes skin healing and cellular regeneration.',
                price: 15,
            },
            {
                title: 'Salt/Sugar Scrub',
                description: 'Exfoliates the skin, enhances circulation, and provides deep hydration, leaving the skin soft and rejuvenated.',
                price: 15,
            },
            {
                title: 'Scalp Massage',
                description: 'Relaxing scalp treatment using nourishing coconut oil for hydration.',
                price: 10,
            },
            {
                title: 'Scalp Massage + Rosemary',
                description: 'Revitalizing treatment that hydrates, enhances circulation, and promotes hair health.',
                price: 15,
            },
            {
                title: 'Scalp Massage + Peppermint',
                description: 'Refreshing massage that soothes tension, stimulates blood flow, and cools the scalp.',
                price: 15,
            },
            {
                title: 'Hot/Cold Contrast Therapy',
                description: 'Alternating heat and cold applications to reduce inflammation, ease pain, and accelerate recovery.',
                price: 15,
            },
            {
                title: 'Aromatherapy',
                description: null,
                price: 5,
                options: [
                    {
                        title: 'Lavender',
                        description: 'Calms the nervous system, reduces stress, and promotes restful sleep with its soothing, floral fragrance.',
                    },
                    {
                        title: 'Eucalyptus',
                        description: 'Clears the mind, supports respiratory health, and soothes tired muscles with its cooling, refreshing scent.',
                    },
                    {
                        title: 'Peppermint',
                        description: 'Invigorates the senses, eases headaches, and relieves muscle tension with its cooling, refreshing effect.',
                    },
                    {
                        title: 'Lemongrass',
                        description: 'Refreshes the body, relieves muscle soreness, and uplifts mood with its invigorating, citrusy aroma.',
                    },
                ],
            },
        ]
    };

    const [state, dispatch] = useReducer(serviceReducer, initialState);

    // Load Services
    const loadServices = async () => {
        await dispatch({
            type: 'SERVICES_LOADED',
            payload: initialState.services,
        });
    };

    // Load Addons
    const loadAddons = async () => {
        await dispatch({
            type: 'ADDONS_LOADED',
            payload: initialState.addons,
        });
    };

    return (
        <ServiceContext.Provider
            value={{
                services: state.services,
                loadServices,
                addons: state.addons,
                loadAddons,
            }}>
            {props.children}
        </ServiceContext.Provider>
    );
};

export default ServiceState;

