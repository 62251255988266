import React from "react";
import "../css/Home.css";
import Popup from "./Popup";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Touch Massage Therapy</title>
        <link rel="canonical" href="https://touch-massagetherapy.com/" />
      </Helmet>

      <Popup trigger={false}>
        <h1>🌕 Full Moon Special 🌕</h1>
        <p>
          With the November 15 full moon, energy and emotions can feel
          heightened. From November 14–16, enjoy $10 off any Reiki session or
          Reiki/Massage combo with code FULLMOON10. Take this time to release,
          rebalance, and find peace. Book now and embrace healing! ✨
        </p>
      </Popup>

      <div className="coming-soon card">
        <img
          src={require("../images/logo.webp")}
          alt="Touch Massage Therapy Logo"
          fetchpriority="high"
        />
        <br />
        <br />
        <p>
          At Touch Massage, we offer a variety of massage techniques so you are
          sure to find the perfect one to suit your needs. Our easy to use
          scheduling system ensures convenience for our clients. Unlike spas
          that offer saunas and facials, we simply specialize in the art of
          massage. Because when you're seeking{" "}
          <span className="fancy-font standout-text ">relaxation, </span>
          <span className="fancy-font standout-text">rejuvenation </span>, or
          <span className="fancy-font standout-text"> relief</span> from
          tension, nothing quite compares to the healing touch of a massage from
          a skilled therapist.
        </p>
        <br />
        <h1 className="fancy-font big-text-1">
          <a href="https://go.booker.com/location/TouchMassageL53735">
            Book Now!!
          </a>
        </h1>
      </div>
    </>
  );
};

export default Home;
